import { Show, splitProps } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { IconProps } from '../props';

export function IconEditPencil01(props: IconProps) {
	const [, svgProps] = splitProps(props, ['title']);

	return (
		<svg
			viewBox="0 0 24 24"
			height="1.2em"
			width="1.2em"
			fill="none"
			stroke="none"
			{...svgProps}
			class={twMerge('inline-flex', svgProps.class)}
			aria-hidden={props.title ? 'false' : 'true'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<Show when={props.title}>
				<title>{props.title}</title>
			</Show>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m12 8l-8 8v4h4l8-8m-4-4l2.869-2.869l.001-.001c.395-.395.593-.593.821-.667a1 1 0 0 1 .618 0c.228.074.425.272.82.666l1.74 1.74c.396.396.594.594.668.822a1 1 0 0 1 0 .618c-.074.228-.272.426-.668.822h0L16 12.001m-4-4l4 4"
			/>
		</svg>
	);
}
